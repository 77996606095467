import useApiCrud from './apiCrud.js'

export default function useApiCrudTable(
  options = {},
  data = {}
){

  let filters = options.filters || {}
  options.filters = {
    page: 1,
    limit: 15,
    sort: 'id',
    ...filters,
  }

  const apiCrud = useApiCrud({
    ...options,
    events: {
      ...options.events,
      index: ( response ) => {

        //custom event before
        if( options.events && options.events.index ){
          response = options.events.index( response )
        }

        //set local table  pagination info
        apiCrud.state.pagination.descending = apiCrud.state.filters.dir == 'desc'
        apiCrud.state.pagination.page = response.meta.current_page
        apiCrud.state.pagination.rowsNumber = response.meta.total
        apiCrud.state.pagination.rowsPerPage = apiCrud.state.filters.limit
        apiCrud.state.pagination.sortBy = apiCrud.state.filters.sort
        return response
      },
      save( response ){
        let index = apiCrud.state.detailIndex

        apiCrud.state.data.splice(
          ( index < 0 ? 0 : index),
          ( index < 0 ? 0 : 1 ),
          response.data
        )

        return response
      },
    },
  }, {
    pagination: {
      descending: options.filters.dir == 'desc',
      page: options.filters.page,
      rowsNumber: 1,
      rowsPerPage: options.filters.limit,
      sortBy: options.filters.sort,
    },
    ...data,
  })

  /**
   * Perform request on change options
   *
   * @param  object props { pagination, filter, getCellValue }
   * @return void
   */
  function onRequest( props )
  {
    const {
      descending,
      page,
      rowsPerPage : limit,
      sortBy : sort
    } = props.pagination

    apiCrud.index({
      page,
      limit,
      sort,
      dir: descending ? 'desc' : 'asc'
    })
  }

  return {
    ...apiCrud,
    onRequest,
  }
}
